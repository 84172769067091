import { metricalpEvent } from '@metricalp/react'
import { ArrowForwardIos } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import { colors, lightBlue, theme } from '../theme'

interface Props {
  adopterId: string
  adopterName: string
  alreadyAdopted: boolean
  animalName: string
  animalId: string
}

export const DogChefPubCard: React.FC<Props> = ({ adopterId, adopterName, alreadyAdopted, animalId, animalName }) => {
  const handleClick = () => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_profil_offers`,
      adopterId: adopterId,
      adopterName: adopterName,
      animalName: animalName,
      animalId: animalId,
      offer: 'dogchef',
    })
    window.open('https://www.dogchef.com/fr/start/?coupon=ZXQ7331', '_blank', 'noopener,noreferrer,noopener,noreferrer')
  }
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          backgroundColor: lightBlue,
          borderRadius: '12px',
          overflow: 'hidden',
          width: '100%',
          height: 130,
          transition: 'all 0.3s',
          mb: 2,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '2px 8px 20px 0 rgba(0,0,0,0.25)',
            '& img': {
              transform: 'scale(1.05)',
            },
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: '45%',
            zIndex: 1,
            transition: 'opacity 0.3s', // Transition for the opacity
            backgroundImage: `linear-gradient(-80deg, ${lightBlue} 50%, rgba(255, 255, 255, 0) 50.5%)`,
          },
        }}
        onClick={handleClick}
      >
        <Box
          component="img"
          src="https://i.imgur.com/4lFbsYO.jpeg"
          alt="Dog food"
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            width: '40%',
            height: '100%', // make image take full height of the container
            zIndex: 0, // Ensure image is behind the pseudo-element
            imageRendering: '-webkit-optimize-contrast',
            transition: 'transform 0.3s ease-in-out', // smooth transition for the scale effect
            transform: 'translateX(-10px)',
            my: -1,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '4px',
            p: 1,
            pl: 0,
            ml: -3.5,
            zIndex: 2,
          }}
        >
          <Typography variant="h5" component="h2" sx={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>
            <span style={{ display: 'block', lineHeight: '24px', fontSize: '14px', fontWeight: 700 }}>
              Des repas de qualité
            </span>
          </Typography>
          <Typography component="p" sx={{ color: 'black', fontSize: '12px', pr: 4, lineHeight: '22px' }}>
            <span style={{ display: 'block', lineHeight: '14px' }}>👉 Un menu adapté à ses besoins</span>
            <span style={{ display: 'block', lineHeight: '14px' }}>👉 Meilleure immunité et digestion</span>
            <span style={{ display: 'block', lineHeight: '14px' }}>👉 Livraison gratuite</span>
          </Typography>
          <Box sx={{ height: '19.5px' }}></Box>
          <Box
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              width: '100%',
            }}
          >
            <Typography
              component="p"
              sx={{ color: theme.palette.secondary.dark, fontSize: '12px', fontWeight: 'bold' }}
            >
              -30% avec le code{' '}
              <Chip
                color="default"
                label="ZXQ7331"
                sx={{
                  fontSize: 12,
                  lineHeight: '10px',
                  height: '18px',
                  backgroundColor: colors.blueSky,
                  color: 'white',
                  '& span': {
                    paddingX: 1,
                  },
                }}
              />
            </Typography>
          </Box>
        </Box>
        <ArrowForwardIos
          sx={{ fontSize: '16px', color: theme.palette.secondary.main, position: 'absolute', right: 5 }}
        />
        <Box
          component="img"
          src={'https://i.imgur.com/D2Lx2B9.png'}
          alt="Dog food"
          sx={{
            objectFit: 'contain',
            height: '20px', // make image take full height of the container
            zIndex: 2, // Ensure image is behind the pseudo-element
            imageRendering: '-webkit-optimize-contrast',
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      </Box>
    </>
  )
}
