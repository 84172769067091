import { metricalpEvent } from '@metricalp/react'
import { ArrowForwardIos } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { CustomersClient } from '../../interactors/clients/CustomersClient'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDto,
  CustomerDto,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { CaatsPubCard } from '../common/CaatsPubCard'
import { FelomeAnimalPubCard } from '../common/FelomeDNAAnimalPubCard'
import { FidelamiPubCard } from '../common/FidelamiPubCard'
import { KozooPubCard } from '../common/KozooPubCard'
import { BrandItem } from '../Shopping/BrandItem'
import { BlogCategory } from '../Shopping/Categories/BlogCategory'
import { CategoryItem } from '../Shopping/CategoryItem'
import { colors, lightBlue, lightRed, theme } from '../theme'
import { AdoptionCongratulationsModal } from './AdoptionCongratulationsModal'
import { AdoptionInfoBox } from './AdoptionInfoBox'
import { AdoptionPaymentPath } from './AdoptionPaymentPath'
import { AdoptionTabs } from './AdoptionTabs'
import { AnimalCard } from './AnimalCard'
import { CaatsSteps } from './CaatsSteps'
import { ConfirmationPopIn, PopIn } from './ConfirmationPopIn'
import { DocumentSignCard } from './DocumentSignCard'
import { DownloadAppCard } from './DownloadAppCard'
import { ImportDocumentCard } from './ImportDocumentCard'
import { CertificateTutorial } from './Tutorial/CertificateTutorial'
import { DogChefPubCard } from '../common/DogChefPubCard'
import { MusoPubCard } from '../common/MusoPubCard'
import { ZamzPubCard } from '../common/ZamzPubCard'
import { AnimautePubCard } from '../common/AnimautePubCard'
import { TelevetPubCard } from '../common/TelevetPubCard'
import { LitiereTranquillePubCard } from '../common/LitiereTranquillePubCard'
import { PurinaPubCard } from '../common/PurinaPubCard'
import { PennyPetPubCard } from '../common/PennyPetPubCard'
import { SterilizationDepositCard } from './SterilizationDepositCard'

const popIn: PopIn = {
  type: 'confirm',
  title: "J'ai effectué le paiement",
  message:
    'En cliquant sur “je confirme” vous déclarez sur l’honneur avoir effectué le virement bancaire ou envoyé un chèque à l’adresse de l’assiociation.',
  cancelText: 'Annuler',
  confirmText: 'Je confirme',
}

export enum Categories {
  Profil = 'profil',
  Action = 'action',
  Default = 'default',
  Blog = 'blog',
  Caats = 'caats',
}

export const AdoptionDetailsScreen: React.FC = () => {
  const [adoptionAttempt, setAdoptionAttempt] = useState<AdoptionAttemptDto | null>(null)
  const [animal, setAnimal] = useState<AnimalDto | null>(null)
  const [association, setAssociation] = useState<CustomerDto | null>(null)
  const [files, setFiles] = useState<File[]>([])
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Categories>(Categories.Default)
  const [isOnGoing, setIsOnGoing] = useState(false)
  const { adoptionId } = useParams() as { adoptionId: string }
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()

  const animalName = adoptionAttempt?.newName || animal?.name

  const adopter = accountsStore.connectedAccount

  const updateAdoptionAttemptMutation = useMutation(
    async (data: {
      adoptionAttempt: FormData
      id: string
      type: 'certificate' | 'contract' | 'payment' | 'sterilization'
      noUpdate?: boolean
    }) => {
      return await AdoptersClient.updateAdoptionAttempt(data)
    },
    {
      onMutate: async (data) => {
        // Return context with the original data so it can be accessed in onSuccess
        return { data }
      },
      onSuccess: (attempt, _, context) => {
        if (!context?.data) {
          return
        }

        const { data } = context

        // Check if noUpdate is set, and exit early if true
        if (data.noUpdate) return

        setAdoptionAttempt(attempt)

        const index = accountsStore.connectedAccount?.adoptionAttempts?.findIndex(
          (adoptionAttempt) => adoptionAttempt.id === data.id
        )

        if (index !== undefined && accountsStore.connectedAccount?.adoptionAttempts) {
          accountsStore.connectedAccount.adoptionAttempts[index] = attempt
        }
      },
    }
  )

  /* const updateAdoptionAttemptMutationDummyInfos = useMutation(
    async (data: { adoptionAttempt: AdoptionAttemptDto; id: string; noUpdate?: boolean }) => {
      return await AdoptersClient.updateAdoptionAttemptDummyInfos(data)
    },
    {
      onMutate: async (data) => {
        // Return context with the original data so it can be accessed in onSuccess
        return { data }
      },
      onSuccess: (attempt, _, context) => {
        if (context?.data.noUpdate) return
        setAdoptionAttempt(attempt)
        const index = accountsStore.connectedAccount?.adoptionAttempts?.findIndex(
          (attempt) => attempt.id === adoptionId
        )
        if (index && accountsStore.connectedAccount && accountsStore.connectedAccount.adoptionAttempts) {
          accountsStore.connectedAccount.adoptionAttempts[index] = attempt
        }
      },
    }
  ) */

  const createAnimalProfile = useMutation(
    async (data: { animalId: string }) => {
      return await AdoptersClient.createAnimalProfileFromAdoptedAnimal(data.animalId)
    },
    {
      onSuccess: (data) => {
        navigate(`/mes-animaux/${data.id}`)
      },
    }
  )

  const handleCreateAnimalProfile = () => {
    if (!animal || !adopter || !adoptionAttempt) return
    metricalpEvent({
      type: `${adoptionAttempt.done ? 'adopted' : 'adoption'}_profil_save`,
      adopterId: adopter.id,
      adopterName: adopter.firstName + ' ' + adopter.lastName,
      animalName: animal?.name,
      animalId: animal?.id,
    })
    createAnimalProfile.mutate({ animalId: animal.id })
  }

  const [congratsModalOpen, setCongratsModalOpen] = useState(true)

  const closeCongratsModal = () => {
    navigate(location.pathname)
    setCongratsModalOpen(false)
  }

  const handleCongratsModal = (secondaryButton?: boolean) => {
    const type =
      congratsType === 'tutorial'
        ? 'access-certificate'
        : congratsType === 'gifts'
        ? 'access-gifts'
        : congratsType === 'certificate'
        ? adoptionAttempt?.contract?.original != null && adoptionAttempt?.contract?.isLocked !== true
          ? 'access-contract'
          : 'access-gifts'
        : 'access-payment'
    metricalpEvent({
      type: `${adoptionAttempt?.done ? 'adopted' : 'adoption'}_popup`,
      action: type,
      adopterId: accountsStore.connectedAccount?.id,
      adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
      animalName: animal?.name,
      animalId: animal?.id,
    })
    if (secondaryButton && congratsType === 'gifts') {
      return navigate(`/adoptions/${adoptionId}?page=profil`)
    }
    if (congratsType === 'gifts') {
      setCongratsModalOpen(false)
      return navigate(`/adoptions/${adoptionId}`)
    }
    if (
      congratsType === 'certificate' &&
      (adoptionAttempt?.contract?.isLocked || !adoptionAttempt?.contract?.original)
    ) {
      setIsOnGoing(true)
      setCongratsModalOpen(false)
      return navigate(`/adoptions/${adoptionId}`)
    }
    if (secondaryButton && congratsType === 'contract') {
      return navigate(`/adoptions/${adoptionId}?page=action&alreadyPaid=true`)
    }

    handleCategoryChange(Categories.Action)
    setCongratsModalOpen(false)
  }

  const fullySetAdoptionAttempt = (attempt: AdoptionAttemptDto) => {
    setAdoptionAttempt(attempt)
    const index = accountsStore.connectedAccount?.adoptionAttempts?.findIndex((attempt) => attempt.id === adoptionId)
    if (index && accountsStore.connectedAccount && accountsStore.connectedAccount.adoptionAttempts) {
      accountsStore.connectedAccount.adoptionAttempts[index] = attempt
    }
  }

  const onSubmit = async (
    data: Partial<AdoptionAttemptDto>,
    type: 'certificate' | 'contract' | 'payment' | 'sterilization',
    noUpdate?: boolean
  ) => {
    if (!adoptionAttempt) return

    const formData = new FormData()

    // Append the selected file if available
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file)
      })
    }

    // Append other form data
    Object.entries({
      ...adoptionAttempt,
      ...data,
    }).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(key, value.toString())
      } else if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false')
      } else if (value && typeof value === 'object') {
        formData.append(key, JSON.stringify(value))
      }
    })

    await updateAdoptionAttemptMutation.mutateAsync({ adoptionAttempt: formData, id: adoptionId, type, noUpdate })
  }

  const onCancel = async () => {
    setOpenPaymentModal(false)
  }

  const onConfirm = async (type?: PopIn['type']) => {
    console.log(type)
    await onSubmit({ step: 4 }, 'payment')
    setOpenPaymentModal(false)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search)
  const congratsType = queryParams.get('congrats') as 'tutorial' | 'certificate' | 'contract' | 'gifts'
  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category as Categories)
    navigate(`${location.pathname}?${`page=${category}`}`)
  }

  const handleActionClick = () => {
    const type = getActionType()
    if (type) {
      metricalpEvent({
        type: `adoption_action`,
        action: `access_${getActionType()}`,
        adopterId: accountsStore.connectedAccount?.id,
        adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
        animalName: animal?.name,
        animalId: animal?.id,
      })
    }
    handleCategoryChange(Categories.Action)
  }

  useEffect(() => {
    const tab = queryParams.get('page') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(Categories.Default)
    }
    if (congratsType) {
      setCongratsModalOpen(true)
    }
  }, [queryParams])

  useEffect(() => {
    const fetchAdoptionAttemptAndAnimal = async () => {
      const adoptionAttempt = accountsStore.connectedAccount?.adoptionAttempts?.find(
        (attempt) => attempt.id === adoptionId
      )
      setAdoptionAttempt(adoptionAttempt || null)

      if (!adoptionAttempt) return

      if ((adoptionAttempt?.preCertificateDialogStep || 1) < 6) {
        navigate(`${location.pathname}?tutorial`)
      }

      const animal = await AnimalsClient.getAnimalById(adoptionAttempt.animalId)
      setAnimal(animal)

      const customer = await CustomersClient.getCurrentCustomer()
      setAssociation(customer)
    }

    fetchAdoptionAttemptAndAnimal()
  }, [])

  useEffect(() => {
    if (adoptionAttempt && adoptionAttempt.preCertificateDialogStep) {
      if ((adoptionAttempt.preCertificateDialogStep || 1) < 6) {
        navigate(`${location.pathname}?tutorial`)
      }
    }
  }, [adoptionAttempt?.preCertificateDialogStep])

  if (!adoptionAttempt || !animal || !association) return null

  const getActionType = (): string | null => {
    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return 'certificate'
    }
    if (!adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return 'certificate'
    }
    if (
      (!adoptionAttempt?.contract?.original || adoptionAttempt?.contract?.isLocked) &&
      !adoptionAttempt?.petHasNewName
    ) {
      return 'newName'
    }
    if (
      adoptionAttempt?.contract?.original &&
      !adoptionAttempt?.contract?.signed &&
      !adoptionAttempt?.contract?.isLocked
    ) {
      return 'contract'
    }

    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return 'payment'
    }

    if (
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      return 'sterilization'
    }

    return null
  }

  const countActions = () => {
    let count = 0
    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      count++
    }
    if (
      (!adoptionAttempt?.contract?.original || adoptionAttempt?.contract?.isLocked) &&
      !adoptionAttempt?.petHasNewName
    ) {
      count++
    }
    if (
      adoptionAttempt?.contract?.original &&
      !adoptionAttempt?.contract?.isLocked &&
      !adoptionAttempt?.contract?.signed
    ) {
      count++
    }
    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      count++
    }

    if (
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      count++
    }

    if (adoptionAttempt?.done) {
      return 0
    }

    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return 0
    }
    return count
  }

  const displayAction = () => {
    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return (
        <Card sx={{ backgroundColor: lightRed }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`Cette adoption a été suspendue. Pour plus d’informations, contactez l’association.`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if (
      adoptionAttempt.done &&
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      return (
        <ImportDocumentCard
          setFiles={setFiles}
          files={files}
          onSubmit={onSubmit}
          filetype="sterilization"
          attemptId={adoptionAttempt.id}
          metricalpInfo={{
            adopterId: accountsStore.connectedAccount?.id,
            adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
            animalName: animal?.name,
            animalId: animal?.id,
          }}
        />
      )
    }

    if (adoptionAttempt.archived) {
      return (
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              <b>Félicitations 🎉</b>
              <br />
              {`Le versement de la contribution a bien été effectué et l'association va pouvoir valider la procédure d’adoption. Avant d’accueillir ${animalName} bénéficiez d’offres exclusives pour encore quelques jours pour préparer son arrivée`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if (!adoptionAttempt?.certificate?.signed) {
      return (
        <>
          {adoptionAttempt?.certificate?.deliveredByHand || !adoptionAttempt?.certificate?.original ? (
            <ImportDocumentCard
              setFiles={setFiles}
              files={files}
              onSubmit={onSubmit}
              filetype="certificate"
              attemptId={adoptionAttempt.id}
              metricalpInfo={{
                adopterId: accountsStore.connectedAccount?.id,
                adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
                animalName: animal?.name,
                animalId: animal?.id,
              }}
            />
          ) : (
            <DocumentSignCard
              attempt={adoptionAttempt}
              type="certificate"
              email={adopter?.email}
              fullySetAdoptionAttempt={fullySetAdoptionAttempt}
              metricalpInfo={{
                adopterId: accountsStore.connectedAccount?.id,
                adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
                animalName: animal?.name,
                animalId: animal?.id,
              }}
            />
          )}
        </>
      )
    }
    /*     if (
      (!adoptionAttempt?.contract?.original || adoptionAttempt?.contract?.isLocked) &&
      !adoptionAttempt?.petHasNewName
    ) {
      return (
        <NewNameAction
          updateMutation={updateAdoptionAttemptMutationDummyInfos}
          attempt={adoptionAttempt}
          actualName={animal.name}
        />
      )
    } */
    if (
      !adoptionAttempt?.contract?.signed &&
      (adoptionAttempt?.contract?.isLocked || !adoptionAttempt?.contract?.original)
    ) {
      return (
        <Card sx={{ backgroundColor: lightBlue, mt: 3 }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              <b>{`L'adoption de ${animalName} n'est pas terminée !`}</b>
              <br />
              {`Si le certificat signé est validé, l'association pourra vous envoyer le contrat. Vous serez informé par e-mail pour le signer et régler les frais d'adoption de ${animalName}.`}
            </Typography>
          </CardContent>
        </Card>
      )
    }
    if (
      adoptionAttempt?.contract?.original &&
      !adoptionAttempt?.contract?.signed &&
      !adoptionAttempt?.contract?.isLocked
    ) {
      return (
        <>
          {adoptionAttempt?.contract?.deliveredByHand ||
          !adoptionAttempt?.contract?.original ||
          adoptionAttempt?.contract?.original === 'NOT_PROVIDED.pdf' ? (
            <ImportDocumentCard
              setFiles={setFiles}
              files={files}
              onSubmit={onSubmit}
              filetype="contract"
              attemptId={adoptionAttempt.id}
              metricalpInfo={{
                adopterId: accountsStore.connectedAccount?.id,
                adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
                animalName: animal?.name,
                animalId: animal?.id,
              }}
            />
          ) : (
            <DocumentSignCard
              attempt={adoptionAttempt}
              type="contract"
              email={adopter?.email}
              fullySetAdoptionAttempt={fullySetAdoptionAttempt}
              metricalpInfo={{
                adopterId: accountsStore.connectedAccount?.id,
                adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
                animalName: animal?.name,
                animalId: animal?.id,
              }}
            />
          )}
        </>
      )
    }

    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return (
        <AdoptionPaymentPath
          animal={animal}
          attempt={adoptionAttempt}
          association={association}
          onSubmit={onSubmit}
          metricalpInfo={{
            adopterId: accountsStore.connectedAccount?.id,
            adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
            animalName: animal?.name,
            animalId: animal?.id,
          }}
        />
      )
    }

    if (
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      return (
        <ImportDocumentCard
          setFiles={setFiles}
          files={files}
          onSubmit={onSubmit}
          filetype="sterilization"
          attemptId={adoptionAttempt.id}
          metricalpInfo={{
            adopterId: accountsStore.connectedAccount?.id,
            adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
            animalName: animal?.name,
            animalId: animal?.id,
          }}
        />
      )
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return (
        <Card sx={{ backgroundColor: lightBlue, mt: 3 }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`La réception du paiement est en cours de confirmation par l’association. En attendant la validation, préparez l’arrivée de ${animalName} !`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentDone) {
      return (
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              <b>Adoption terminée</b>
              <br />
              {`Vérifiez que vous disposez de toutes les informations de santé nécessaire dans son carnet de santé `}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, my: 2 }}>
        <Card sx={{ backgroundColor: lightBlue }}>
          <CardContent>
            <Typography variant="body1" component="p" sx={{ fontSize: '14px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`Le certificat d’engagement rappelle qu’un budget annuel de 50 à 200 € est nécessaire pour assurer les soins courants d’un animal en bonne santé.`}
              <br />
              {`Avec Kozoo, estimez gratuitement le coût d’une assurance personnalisable pour couvrir la santé de ${animalName}`}
            </Typography>
          </CardContent>
        </Card>
        <BrandItem
          animalProfile={animal}
          title="Assurance Kozoo"
          description={`Remboursement de 50% à 100%\n
        Plafond de 1000€ à 3000€`}
          logoUrl="https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg"
          imgSrc="https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg"
          brandName="kozoo"
          catchPhrase="Dès 5,44€/mois"
          redirectUrl="https://bit.ly/4a6FFAJ"
        />
      </Box>
    )
  }

  const displayActionText = (): { title?: string; text: string } => {
    if (
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByUser ||
      adoptionAttempt?.status === AdoptionAttemptDtoStatus.SuspendedByCustomer
    ) {
      return { title: `Cette adoption a été suspendue.`, text: `Pour plus d’informations, contactez l’association.` }
    }

    if (
      adoptionAttempt.done &&
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      return { text: `Certificat de stérilisation à envoyer` }
    }

    if (adoptionAttempt.archived) {
      return {
        title: 'Félicitations 🎉',
        text: `Le versement de la contribution a bien été effectué et l'association va pouvoir valider la procédure d’adoption.`,
      }
    }

    if (adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return { text: `Certificat d'engagement à signer` }
    }
    if (!adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed) {
      return { text: `Certificat d'engagement remis en main propre` }
    }
    /*     if (
      (!adoptionAttempt?.contract?.original || adoptionAttempt?.contract?.isLocked) &&
      !adoptionAttempt?.petHasNewName
    ) {
      return { text: `Rebaptiser ${animal.name}` }
    } */
    if (!adoptionAttempt?.contract?.original || adoptionAttempt?.contract?.isLocked) {
      return {
        title: 'Validation du certificat signé en cours',
        text: `En attente de confirmation de l’association`,
      }
    }
    if (
      adoptionAttempt?.contract?.original &&
      !adoptionAttempt?.contract?.signed &&
      !adoptionAttempt?.contract?.isLocked
    ) {
      return { text: `Contrat d'adoption à signer` }
    }

    if (adoptionAttempt?.contract?.signed && !adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return { text: `Réglement de la contribution` }
    }

    if (
      adoptionAttempt?.sterilization?.needed &&
      !adoptionAttempt?.sterilization?.file &&
      adoptionAttempt.sterilization.date &&
      dayjs(adoptionAttempt.sterilization.date).subtract(2, 'weeks').isBefore(dayjs())
    ) {
      return { text: `Certificat de stérilisation à envoyer` }
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentSentByUser && !adoptionAttempt?.paymentDone) {
      return { title: `Paiement en cours de réception`, text: `En attendant profitez de nos offres exclusives` }
    }

    if (adoptionAttempt?.contract?.signed && adoptionAttempt?.paymentDone) {
      return {
        title: `Adoption terminée`,
        text: `Vérifier que vous disposez de toutes les informations de santé nécessaire dans son carnet de santé`,
      }
    }

    return { title: 'Une action facultative:', text: `Obtenir un devis d'assurance gratuit pour ${animalName}` }
  }

  const displayError = () => {
    if (
      adoptionAttempt?.certificate?.original &&
      !adoptionAttempt?.certificate?.signed &&
      adoptionAttempt?.certificate?.refusedReason
    ) {
      return (
        <AdoptionInfoBox
          message={`Il y a un problème avec le certficat que vous avez transmis : "${adoptionAttempt.certificate.refusedReason}". Merci de corriger l'erreur et de renvoyer le certificat signé.`}
          type="error"
        />
      )
    }
    if (
      adoptionAttempt?.contract?.original &&
      !adoptionAttempt?.contract?.signed &&
      adoptionAttempt?.contract?.refusedReason
    ) {
      return (
        <AdoptionInfoBox
          message={`Il y a un problème avec le contrat que vous avez transmis : "${adoptionAttempt.contract.refusedReason}". Merci de corriger l'erreur et de renvoyer le contrat signé.`}
          type="error"
        />
      )
    }
    if (!adoptionAttempt?.sterilization?.file && adoptionAttempt?.sterilization?.refusedReason) {
      return (
        <AdoptionInfoBox
          message={`Il y a un problème avec le certificat de stérilisation que vous avez transmis : "${adoptionAttempt.sterilization.refusedReason}". Merci de corriger l'erreur et de renvoyer le certificat.`}
          type="error"
        />
      )
    }
  }

  const isConsideredDone: boolean =
    adoptionAttempt.paymentDone === true ||
    adoptionAttempt.paymentSentByUser === true ||
    adoptionAttempt.done === true ||
    adoptionAttempt.archived === true ||
    adoptionAttempt.certificate?.deliveredByHand === true ||
    adoptionAttempt.certificate?.original === 'NOT_PROVIDED.pdf' ||
    adoptionAttempt.certificate?.original === ''
      ? true
      : false

  const congratsModalShouldOpen: boolean =
    congratsType === 'tutorial'
      ? !adoptionAttempt.certificate?.signed && !!adoptionAttempt.certificate?.original
      : congratsType === 'gifts'
      ? true
      : congratsType === 'certificate'
      ? !adoptionAttempt.contract?.signed
      : congratsType === 'contract' && !adoptionAttempt.paymentSentByUser && !adoptionAttempt.paymentDone

  return (adoptionAttempt.preCertificateDialogStep || 1) < 6 &&
    (animal.species === 'dog' || animal.species === 'cat') ? (
    <CertificateTutorial
      animal={animal}
      attemptId={adoptionAttempt.id}
      setAdoptionAttempt={setAdoptionAttempt}
      initialStep={
        adoptionAttempt.preCertificateDialogStep
          ? adoptionAttempt.preCertificateDialogStep
          : adoptionAttempt.done
          ? 9
          : 0
      }
      animalName={animalName || ''}
      alreadyAdopted={adoptionAttempt.done}
      certificateHandDelivered={
        adoptionAttempt.certificate?.deliveredByHand === true ||
        adoptionAttempt.certificate?.original === 'NOT_PROVIDED.pdf' ||
        adoptionAttempt.certificate?.original === ''
      }
    />
  ) : (
    <Box sx={{ paddingX: { xs: 0, md: 2 }, minHeight: '100%' }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', px: 2 }}>
        {selectedCategory === Categories.Action ? (
          displayAction()
        ) : selectedCategory === Categories.Caats ? (
          <CaatsSteps
            animal={animal}
            skipEnd={isOnGoing}
            animalName={animalName || ''}
            alreadyAdopted={adoptionAttempt?.done}
            adopterId={adopter?.id || ''}
            adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
          />
        ) : selectedCategory === Categories.Blog ? (
          <BlogCategory animalProfile={animal} />
        ) : selectedCategory === Categories.Profil ? (
          <AdoptionTabs
            animalProfile={animal}
            attempt={adoptionAttempt}
            assoEmail={association.email || ''}
            alreadyAdopted={adoptionAttempt?.done}
            adopterId={adopter?.id || ''}
            adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
          />
        ) : (
          <Grid container spacing={2} sx={{ py: 4 }}>
            <Grid item xs={12} md={3.5}>
              <AnimalCard
                animal={animal}
                association={association}
                handleCategoryChange={handleCategoryChange}
                animalName={animalName || ''}
                adopterId={adopter?.id || ''}
                adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                alreadyAdopted={adoptionAttempt?.done || false}
              />
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '12px',
                  mb: 2,
                }}
              >
                {displayError()}
                {adoptionAttempt.done ? (
                  <Box
                    sx={{
                      backgroundColor: theme.palette.success.main,
                      color: 'white',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      boxShadow: 'none',
                      borderRadius: '12px',
                      p: '6px 16px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'left',
                        textAlign: 'left',
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>Adoption terminée</Typography>
                      <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
                        Vérifier que vous disposez de toutes les informations de santé nécessaire dans son carnet de
                        santé
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor:
                        displayActionText().title !== 'Adoption terminée'
                          ? theme.palette.secondary.main
                          : theme.palette.success.main,
                      color: 'white',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      boxShadow: 'none',
                      borderRadius: '12px',
                      '&:hover': {
                        backgroundColor:
                          displayActionText().title !== 'Adoption terminée'
                            ? theme.palette.secondary.dark
                            : theme.palette.success.dark,
                      },
                      '&:disabled': {
                        py: '13px',
                        border: 'none',
                        backgroundColor: 'rgba(243, 243, 245, 1)',
                        color: '#696B6D',
                      },
                    }}
                    onClick={() => handleActionClick()}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'left',
                        textAlign: 'left',
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        {displayActionText().title ?? `${countActions()} action(s) à mener`}
                      </Typography>
                      <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>{displayActionText().text}</Typography>
                    </Box>
                    {displayActionText().title !== 'Adoption terminée' && <ArrowForwardIos sx={{ color: 'white' }} />}
                  </Button>
                )}
              </Box>
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <>
                  <Typography variant="h2" sx={{ mt: 3, mb: 2, fontSize: 20, color: colors.blueSky, fontWeight: 400 }}>
                    Les meilleurs avantages pour {animalName} <br /> pour
                    <span style={{ fontWeight: 700, textDecoration: 'line-through' }}> seulement 3,99€/mois</span>
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: colors.blueSky,
                      color: 'white',
                      fontSize: '20px',
                      fontWeight: 700,
                      width: 'fit-content',
                      p: 1,
                      py: 0.5,
                      pr: 2,
                      borderRadius: '11px',
                      transform: 'rotate(-1deg)',
                      mb: 2,
                    }}
                  >
                    Une semaine gratuite !
                  </Box>
                </>
              )}
              {isConsideredDone && (animal.species === 'cat' || animal.species === 'dog') && (
                <PurinaPubCard
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                  alreadyAdopted={true}
                  animalName={animalName || ''}
                  animalId={animal.id}
                />
              )}
              {isConsideredDone && animal.species === 'cat' && (
                <LitiereTranquillePubCard
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                  alreadyAdopted={true}
                  animalName={animalName || ''}
                  animalId={animal.id}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <KozooPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && animal.species === 'cat' && (
                <CaatsPubCard
                  attemptId={adoptionAttempt.id}
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && animal.species === 'dog' && (
                <DogChefPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <MusoPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <PennyPetPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={true}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <AnimautePubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <TelevetPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <ZamzPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && (animal.species === 'dog' || animal.species === 'cat') && (
                <FidelamiPubCard
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {isConsideredDone && animal.species === 'cat' && <FelomeAnimalPubCard animalName={animalName || ''} />}

              {adoptionAttempt?.sterilization?.needed &&
                adoptionAttempt?.sterilization?.depositStatus !== 'paid' &&
                adoptionAttempt?.sterilization?.depositStatus !== 'refunded' && (
                  <SterilizationDepositCard
                    amount={adoptionAttempt.sterilization?.depositAmount}
                    order={association.paymentInfo?.checkOrder}
                  />
                )}

              {(!adoptionAttempt?.paymentDone || !adoptionAttempt?.archived) && (
                <DownloadAppCard
                  attempt={adoptionAttempt}
                  fullySetAdoptionAttempt={fullySetAdoptionAttempt}
                  animalName={animalName || ''}
                  animalId={animal.id}
                  alreadyAdopted={adoptionAttempt?.done || false}
                  adopterId={adopter?.id || ''}
                  adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
                />
              )}
              {adoptionAttempt?.hasSeenTutorialToInstallApp && (
                <CategoryItem
                  title="Articles et conseils"
                  slug="blog"
                  backgroundColor={'#6c757d'}
                  imgSrc={'https://animals-photos.s3.amazonaws.com/Article+et+conseils+-+Chats-min.jpg'}
                  selectACategory={handleCategoryChange}
                  halfSize={false}
                  disabled={false}
                  sx={{ mt: 2 }}
                />
              )}
              {((adoptionAttempt?.contract?.signed &&
                (adoptionAttempt?.paymentSentByUser || adoptionAttempt?.paymentDone)) ||
                adoptionAttempt?.done) && (
                <>
                  <div style={{ height: '80px' }} />
                  <Box
                    sx={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      padding: 2,
                      backgroundColor: 'white',
                      zIndex: 10,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontStyle="italic"
                      fontSize={12}
                      color="rgba(87, 87, 87, 1)"
                      textAlign="center"
                    >
                      Vous ne pourrez plus bénéficier des offres d&apos;arrivée une fois le profil créé
                    </Typography>
                    <LoadingButton
                      variant="contained"
                      sx={{ fontSize: '18px' }}
                      fullWidth
                      onClick={() => handleCreateAnimalProfile()}
                      loading={createAnimalProfile.isLoading}
                    >
                      {`Terminer et créer le profil`}
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        )}
        {congratsType ? (
          congratsModalShouldOpen ? (
            <AdoptionCongratulationsModal
              open={congratsModalOpen}
              handleClose={closeCongratsModal}
              handleNext={handleCongratsModal}
              type={congratsType}
              animalName={animalName || ''}
              customerName={association.name}
              useVariant={
                congratsType === 'certificate' &&
                (adoptionAttempt?.contract?.isLocked || !adoptionAttempt?.contract?.original)
              }
            />
          ) : (
            <Navigate to={`${location.pathname}`} replace />
          )
        ) : null}
        <ConfirmationPopIn popIn={popIn} isModalOpened={openPaymentModal} onConfirm={onConfirm} onCancel={onCancel} />
      </Box>
    </Box>
  )
}
