import { Box } from '@mui/material'
import React from 'react'
import { AdopterDto, AnimalDto, AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { AnimautePubCard } from '../../common/AnimautePubCard'
import { CaatsPubCard } from '../../common/CaatsPubCard'
import { DogChefPubCard } from '../../common/DogChefPubCard'
import { FelomeAnimalPubCard } from '../../common/FelomeDNAAnimalPubCard'
import { FidelamiPubCard } from '../../common/FidelamiPubCard'
import { KozooPubCard } from '../../common/KozooPubCard'
import { MusoPubCard } from '../../common/MusoPubCard'
import { TelevetPubCard } from '../../common/TelevetPubCard'
import { ZamzPubCard } from '../../common/ZamzPubCard'
import { LitiereTranquillePubCard } from '../../common/LitiereTranquillePubCard'
import { PurinaPubCard } from '../../common/PurinaPubCard'
import { PennyPetPubCard } from '../../common/PennyPetPubCard'

interface Props {
  adopter: AdopterDto
  animal: AnimalProfileDto | AnimalDto
}

export const DealsCategory: React.FC<Props> = ({ adopter, animal }) => {
  const animalName = animal.name
  const attemptId = 'unknown'
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        mb: 2,
      }}
    >
      {(animal.species === 'cat' || animal.species === 'dog') && (
        <PurinaPubCard
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
          alreadyAdopted={true}
          animalName={animalName || ''}
          animalId={animal.id}
        />
      )}
      {animal.species === 'cat' && (
        <LitiereTranquillePubCard
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
          alreadyAdopted={true}
          animalName={animalName || ''}
          animalId={animal.id}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <KozooPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {animal.species === 'cat' && (
        <CaatsPubCard
          attemptId={attemptId}
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {animal.species === 'dog' && (
        <DogChefPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <MusoPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <PennyPetPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <AnimautePubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <TelevetPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <ZamzPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {(animal.species === 'dog' || animal.species === 'cat') && (
        <FidelamiPubCard
          animalName={animalName || ''}
          animalId={animal.id}
          alreadyAdopted={true}
          adopterId={adopter?.id || ''}
          adopterName={`${adopter?.firstName} ${adopter?.lastName}`}
        />
      )}
      {animal.species === 'cat' && <FelomeAnimalPubCard animalName={animalName || ''} />}
    </Box>
  )
}
