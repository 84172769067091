import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'

import { setOpacity } from '../../utils/setOpacity'

export type MessageType = 'warning' | 'error' | 'info'

interface InfoBoxProps {
  content: string
  messageType: MessageType
  sx?: SxProps<Theme>
}

export const InfoBox: React.FC<InfoBoxProps> = ({ content, messageType, sx }) => {
  return (
    <Box
      sx={{
        padding: 2,
        color: (theme) => theme.palette.common.black,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
        border: (theme) => `1px solid ${theme.palette[messageType].main}`,
        borderRadius: (theme) => theme.spacing(0.5),
        margin: (theme) => theme.spacing(1, 0),
        fontSize: (theme) => theme.typography.body2.fontSize,
        background: (theme) => setOpacity(theme.palette[messageType].main, 0.2),
        fontWeight: 700,
        ...sx,
      }}
    >
      {content}
    </Box>
  )
}
