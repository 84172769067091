import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { lightRed } from '../theme'

interface Props {
  amount: number | undefined
  order: string | undefined
}

export const SterilizationDepositCard: React.FC<Props> = ({ amount, order }) => {
  return (
    <Card sx={{ backgroundColor: lightRed }}>
      <CardContent>
        <Typography sx={{ fontWeight: 700 }}>Caution de stérilisation</Typography>
        <Typography
          variant="body1"
          gutterBottom
          component="p"
          sx={{ fontSize: '14px', lineHeight: '17.2px', fontWeight: 400 }}
        >
          {amount && order
            ? `Dans le cadre de l’adoption, l’animal doit être stérilisé post adoption. Un chèque de caution d’un montant de 
        ${amount}€ à l’ordre de "${order}" devra être adressé à l’association.`
            : `Dans le cadre de l’adoption, si l’animal doit être stérilisé post adoption un chèque de caution devra être adressé à l’association, merci de vous rapprocher de votre interlocuteur.trice.`}
        </Typography>
      </CardContent>
    </Card>
  )
}
