import { metricalpEvent } from '@metricalp/react'
import { CheckCircleOutline, ShoppingCart, Warning } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthClient } from '../../interactors/clients/AuthClient'
import { backendClient } from '../../interactors/clients/client'
import { AdoptionAttemptDto, AnimalDto, CustomerDto } from '../../interactors/gen/backendClient'
import { useBasketStore } from '../../store/BasketStore'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { CheckoutForm } from '../common/StripeCheckoutForm'
import data from '../Shopping/data/products.json'
import { colors, theme } from '../theme'
import { AccessoriesTab } from './AccessoriesTab'
import { AdoptionDonationEditModal } from './AdoptionDonationEditModal'
import { AdoptionPriceDetailsModal } from './AdoptionPriceDetailsModal'
import { MetricalpInfo } from './DocumentSignCard'
import { PaymentMarketStepModal } from './PaymentMarketStepModal'
import { InfoBox } from '../common/InfoBox'

interface Props {
  attempt: AdoptionAttemptDto
  animal: AnimalDto
  association: CustomerDto | null
  onSubmit: (
    data: Partial<AdoptionAttemptDto>,
    type: 'certificate' | 'contract' | 'payment',
    noUpdate?: boolean
  ) => Promise<void>
  metricalpInfo: MetricalpInfo
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export const AdoptionPaymentPath: React.FC<Props> = ({ animal, attempt, association, onSubmit, metricalpInfo }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const initialStep = queryParams.get('step')
  const navigate = useNavigate()
  const basketStore = useBasketStore()
  const basket = basketStore.basket
  const DEPOSIT = Number(attempt.depositAmount) || 0
  const HAS_DEPOSIT = DEPOSIT > 0 && attempt.depositStatus === true
  const ADOPTION_PRICE = Number(animal.adoption.priceInEuros) || 0
  const [marketModalOpened, setMarketModalOpened] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [donation, setDonation] = useState<number>(10)
  const [errored, setErrored] = useState<boolean>(false)
  const [alreadyPaidAdoptionFees, setAlreadyPaidAdoptionFees] = useState<boolean>(
    queryParams.get('alreadyPaid') === 'true' || false
  )

  const animalName = attempt?.newName || animal?.name

  const stripeAccountId = association?.stripeAccountId
  const canPayAdoptionByStripe = stripeAccountId !== undefined

  const onClose = () => {
    metricalpEvent({
      type: `adoption_action`,
      action: `access_discount`,
      adopterId: metricalpInfo.adopterId,
      adopterName: metricalpInfo.adopterName,
      animalName: metricalpInfo.animalName,
      animalId: metricalpInfo.animalId,
    })
    setMarketModalOpened(false)
  }

  const total =
    Math.round(
      (donation +
        (alreadyPaidAdoptionFees ? 0 : ADOPTION_PRICE) -
        (HAS_DEPOSIT && !alreadyPaidAdoptionFees ? DEPOSIT : 0) +
        Number.EPSILON) *
        100
    ) / 100

  const [step, setStep] = useState<number>(initialStep ? Number(initialStep) : 1)

  const checkIfAlreadyPaidAdoptionFees = async () => {
    const account = await AuthClient.getCurrentAccount()
    if (account && account.adoptionAttempts) {
      const isAdoptionPaid = account.adoptionAttempts.some(
        (adoption) => adoption.id === attempt.id && adoption.paymentDone === true
      )
      return isAdoptionPaid
    }
    return false
  }

  const handleStepForward = async () => {
    // Short circuit for now because we don't want to show the market step
    if (total === 0) {
      if (alreadyPaidAdoptionFees) {
        onSubmit({ step: 4 }, 'payment')
        metricalpEvent({
          type: `adoption_action`,
          action: `done_payment`,
          adopterId: metricalpInfo.adopterId,
          adopterName: metricalpInfo.adopterName,
          animalName: metricalpInfo.animalName,
          animalId: metricalpInfo.animalId,
        })
      }
      return navigate(`/adoptions/${attempt.id}?congrats=gifts`)
    } else {
      const isAdoptionPaid = await checkIfAlreadyPaidAdoptionFees()
      if (isAdoptionPaid) {
        return window.open(`https://adoptant.petso.fr/adoptions/${attempt.id}`)
      }
      try {
        createPaymentIntent()
      } catch (e) {
        setStep(1)
        setErrored(true)
        console.error(e)
      }
      setStep(4)
      return navigate(`/adoptions/${attempt.id}?page=action&step=${4}`)
    }
    /* if (step === 2) {
      if (total === 0) {
        if (alreadyPaidAdoptionFees) {
          onSubmit({ step: 4 }, 'payment')
          metricalpEvent({
            type: `adoption_action`,
            action: `done_payment`,
            adopterId: metricalpInfo.adopterId,
            adopterName: metricalpInfo.adopterName,
            animalName: metricalpInfo.animalName,
            animalId: metricalpInfo.animalId,
          })
        }
        return navigate(`/adoptions/${attempt.id}`)
      }
    }
    if (step === 3) {
      if (alreadyPaidAdoptionFees) {
        onSubmit({ step: 4 }, 'payment', true)
        metricalpEvent({
          type: `adoption_action`,
          action: `done_payment`,
          adopterId: metricalpInfo.adopterId,
          adopterName: metricalpInfo.adopterName,
          animalName: metricalpInfo.animalName,
          animalId: metricalpInfo.animalId,
        })
      }
      createPaymentIntent()
    }
    setStep(step + 1)
    navigate(`/adoptions/${attempt.id}?page=action&step=${step + 1}`) */
  }

  useEffect(() => {
    setStep(initialStep ? Number(initialStep) : 1)
  }, [queryParams])

  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [donationModalOpened, setDonationModalOpened] = useState<boolean>(false)

  const handleDonationChange = (_: React.MouseEvent<HTMLElement>, newDonation: number | null) => {
    if (newDonation !== null) {
      setDonation(newDonation)
    }
  }

  const createPaymentIntent = async () => {
    const response = await backendClient.post(
      `/adopters-platform/stripe/create-payment-intent?attemptId=${attempt.id}&associationStripeAccountId=${stripeAccountId}`,
      {
        items: [
          ...(alreadyPaidAdoptionFees || !canPayAdoptionByStripe
            ? []
            : [{ reference: 'ADOPTION', quantity: 1, price: (ADOPTION_PRICE - (HAS_DEPOSIT ? DEPOSIT : 0)) * 100 }]),
          { reference: 'DONATION', quantity: 1, price: donation * 100 },
        ],
      }
    )
    setClientSecret(response.data)
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  const Step1 = () => (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'start', justifyContent: 'start', py: 4 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
          }}
        >
          {errored && (
            <InfoBox
              sx={{ mt: -1, p: 1 }}
              messageType="error"
              content={`Votre paiement n'a pas été pris en compte car l'association n'a pas finalisé la configuration du paiement en ligne. Pour continuer, utilisez un autre mode de paiement et cliquez sur le bouton "J'ai déjà effectué le réglement"`}
            />
          )}
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              pr: 2,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Les frais d&apos;adoption :
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              width: '100%',
              mt: 2,
              px: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'rgba(87, 87, 87, 1)',
                fontStyle: 'italic',
                textDecoration: alreadyPaidAdoptionFees ? 'line-through' : 'none',
              }}
            >
              Frais d&apos;adoption de {animalName} :
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontWeight: 700 }}>
              {alreadyPaidAdoptionFees ? 0 : ADOPTION_PRICE}€
            </Typography>
          </Box>
          {HAS_DEPOSIT && !alreadyPaidAdoptionFees && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
                width: '100%',
                mt: 2,
                px: 1,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'rgba(87, 87, 87, 1)',
                  fontStyle: 'italic',
                  textDecoration: alreadyPaidAdoptionFees ? 'line-through' : 'none',
                }}
              >
                Acompte reçu :
              </Typography>
              <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontWeight: 700 }}>
                -{DEPOSIT}€
              </Typography>
            </Box>
          )}
          {alreadyPaidAdoptionFees && (
            <Box sx={{ pl: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1, width: '100%' }}>
                <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '26px' }} />
                <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={14}>
                  Vous affirmez avoir déjà réglé les frais d&apos;adoption. L’association validera votre contribution.{' '}
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ fontStyle: 'italic', color: colors.blueSky, fontWeight: 700, p: '2px' }}
                    onClick={() => setAlreadyPaidAdoptionFees(false)}
                  >
                    Modifier
                  </Button>
                </Typography>
              </Box>
            </Box>
          )}
          {!canPayAdoptionByStripe && !alreadyPaidAdoptionFees && (
            <Box sx={{ pl: 1, width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                }}
              >
                <Warning sx={{ color: colors.amber, fontSize: '26px' }} />
                <Typography variant="body1" color={'black'} fontWeight={700} fontSize={14}>
                  {`L'association n'a pas encore configuré le paiement en ligne.`}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              gap: 1,
              width: '100%',
              mt: 2,
              px: 1,
            }}
          >
            <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontStyle: 'italic' }}>
              Votre contribution à Petso :
              <br />
              <span
                style={{ fontStyle: 'normal', fontWeight: 700, color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => setModalOpened(true)}
              >
                Pourquoi ?
              </span>
              <span style={{ fontStyle: 'normal', fontWeight: 700, color: theme.palette.primary.main }}>{` - `}</span>
              <span
                style={{ fontStyle: 'normal', fontWeight: 700, color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => setDonationModalOpened(true)}
              >
                Modifier
              </span>
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontWeight: 700 }}>
              {donation}€
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              width: '100%',
              mt: 1,
              px: 1,
            }}
          >
            <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontStyle: 'italic' }}>
              Total :
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontWeight: 700 }}>
              {total}€
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
          }}
        >
          {!canPayAdoptionByStripe && !alreadyPaidAdoptionFees && (
            <Typography
              variant="body1"
              color={'black'}
              fontWeight={700}
              fontSize={14}
              sx={{ mt: 1, textAlign: 'center' }}
            >
              {`Pour continuer, utilisez un autre mode de paiement et cliquez sur "J'ai déjà effectué le réglement".`}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ fontSize: '18px' }}
            disabled={!canPayAdoptionByStripe && !alreadyPaidAdoptionFees}
            onClick={handleStepForward}
          >
            {`Payer ${total}€`}
          </Button>
          {!alreadyPaidAdoptionFees && (
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ fontSize: '18px' }}
              onClick={() => setAlreadyPaidAdoptionFees(true)}
            >
              {`J'ai déjà effectué le réglement`}
            </Button>
          )}
        </Box>
      </Box>
      <AdoptionPriceDetailsModal open={modalOpened} handleClose={() => setModalOpened(false)} />
      <AdoptionDonationEditModal
        open={donationModalOpened}
        handleClose={() => setDonationModalOpened(false)}
        donation={donation}
        handleDonationChange={handleDonationChange}
      />
    </>
  )

  const Step2 = () => (
    <>
      <PaymentMarketStepModal
        handleClose={onClose}
        open={marketModalOpened}
        handleStepForward={handleStepForward}
        animalName={animalName}
        profileImageKey={animal?.images?.profileImageKey || ''}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, mb: 3, mt: 1 }}>
        <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '36px' }} />
        <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={15}>
          En adoptant auprès de l’association, Bénéficiez de réduction exclusive de 10%
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 3,
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        {`Pour préparer l'arrivée de ${animalName}`}
      </Box>
      <AccessoriesTab animalProfile={animal} />
      <Button
        variant="contained"
        color="primary"
        sx={{ fontSize: '18px', position: 'fixed', bottom: 40, left: { xs: 20, md: '260px' }, right: 20 }}
        onClick={handleStepForward}
      >
        {basketStore.totalPrice() > 0
          ? `Ajouter (${Math.round((basketStore.totalPrice() + Number.EPSILON) * 100) / 100} €)`
          : `Passer l'étape`}
      </Button>
    </>
  )

  const Step3 = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', py: 4 }}>
      <Box
        sx={{
          mb: 3,
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        {`Récapitulatif`}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'start',
            justifyContent: 'start',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1, mt: 1, width: '100%' }}>
            <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '26px' }} />
            <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={14}>
              Le code promo a bien été appliqué
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 2,
              p: 2,
              borderRadius: 2,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ShoppingCart color="primary" />
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Récapitulatif de votre commande</Typography>
            </Box>
            {basket.map((item) => (
              <Box
                key={item.reference}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #E0E0E0',
                  pb: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {data.products.find((i) => item.reference === i.reference)?.title} x {item.quantity}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {(item.price * item.quantity).toFixed(2)}€
                </Typography>
              </Box>
            ))}
            {!alreadyPaidAdoptionFees && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #E0E0E0',
                  pb: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Frais d&apos;adoption</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{ADOPTION_PRICE}€</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #E0E0E0',
                pb: 1,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Donation</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{donation}€</Typography>
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#888' }}>Frais de port</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#888' }}>
                {fdp > 0 ? `${fdp}€` : 'Offerts'}
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Total</Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>{total}€</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ fontSize: '18px', mt: 1 }}
            onClick={handleStepForward}
          >
            {`Payer ${total}€`}
          </Button>
        </Box>
      </Box>
    </Box>
  )

  const Step4 = () => {
    if (!clientSecret) {
      return <CircularProgressPanel absolute={false} />
    }
    return (
      <Box
        sx={{
          py: 4,
        }}
      >
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            price={total}
            redirectionLink={`https://adoptant.petso.fr/adoptions/${attempt.id}?congrats=gifts`}
            buttonFontSize="18px"
          />
        </Elements>
      </Box>
    )
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      case 3:
        return <Step3 />
      case 4:
        return <Step4 />
      default:
        return null
    }
  }

  return renderStep()
}
